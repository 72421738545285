import { Typography } from 'components/Typography';
import { FormSimulateTechnicianAction } from './FormSimulateTechnicianAction';
import { Button } from 'components/Button';
import { useUI } from 'hooks/useUI';
import { ModalTypes } from 'ducks/ui/types';
import { Call } from 'services/call';
import { useQueryClient } from 'react-query';

interface SimulateTechnicianActionProps {
  technicianId: string;
  status: string;
  nameTechnician: string;
  callId: number;
}

const SimulateTechnicianAction = ({
  technicianId,
  status,
  nameTechnician,
  callId,
}: SimulateTechnicianActionProps) => {
  const UI = useUI();
  const queryClient = useQueryClient();

  const handleAccept = () => {
    UI.operations.modal.openModal({
      type: ModalTypes.ModalGenericConfirmation,
      modalProps: {
        onConfirm: async () => {
          try {
            await Call.acceptAdminCall(callId, technicianId);
            UI.operations.showToast('Chamado aceito com sucesso!', {
              type: 'success',
            });

            queryClient.refetchQueries('call');
          } catch (error) {
            UI.operations.showToast(error.response.data.message, {
              type: 'error',
            });
          }
        },
        title: 'Forçar aceitação',
        description: 'Deseja forçar a aceitação do chamado?',
        confirmText: 'Sim',
        cancelText: 'Não',
      },
    });
  };

  return (
    <div className="flex flex-col px-6 py-8 pb-8 space-y-4 ">
      <Typography variant="h2" color="darkestGray">
        Iniciar atendimento
      </Typography>
      <div className="bg-lighterGray rounded-lg p-4 space-y-6">
        <Typography variant="p1" color="darkerGray">
          O início do chamado deve ser realizado pelo técnico, sendo iniciado
          pelo Guio somente em casos excepcionais. Certifique-se de que o
          técnico esteja presente na empresa antes de prosseguir.
        </Typography>

        {!technicianId && (
          <Typography variant="p1" color="orange">
            É necessário vincular um técnico para iniciar o atendimento.
          </Typography>
        )}

        {technicianId && status === 'WAITING_TECHNICIANS_ACCEPTANCE' && (
          <div className="space-y-4">
            <Typography variant="h4" color="darkestGray">
              O técnico
              <span className="font-bold">
                {' '}
                &quot; {nameTechnician} &quot;{' '}
              </span>
              ainda não aceitou o chamado, gostaria de forçar a aceitação?
            </Typography>

            <div>
              <Button
                color="purple"
                variant="contained"
                width={100}
                onClick={handleAccept}
              >
                Sim
              </Button>
            </div>
          </div>
        )}

        {technicianId && status !== 'WAITING_TECHNICIANS_ACCEPTANCE' && (
          <FormSimulateTechnicianAction callId={callId} />
        )}
      </div>
    </div>
  );
};
export { SimulateTechnicianAction };
