export function downloadCSV({ data }) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: 'text/csv' })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `relatorio_${new Date().toLocaleDateString()}.csv`
  );
  document.body.appendChild(link);
  link.click();
  return data;
}
