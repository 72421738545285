import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Form, Formik } from 'formik';
import { useUI } from 'hooks/useUI';
import { useCall } from 'queries/call';
import { Call } from 'services/call';
import { useQueryClient } from 'react-query';

const FormSimulateTechnicianAction = ({ callId }) => {
  const { call } = useCall(callId);
  const queryClient = useQueryClient();
  const UI = useUI();

  return (
    <Formik
      initialValues={{
        startedAt: null,
        endTime: null,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (values.startedAt) {
            await Call.initAdminCall(callId, {
              startedAt: values.startedAt,
            });
          }

          if (values.endTime) {
            await Call.endCallAdmin(callId, {
              endTime: values.endTime,
              completedSuccessfully: true,
              responsibleWasPresent: true,
              description: 'Chamado finalizado pelo Guio, simulando o técnico',
            });
          }

          UI.operations.showToast('Chamado atualizado com sucesso!', {
            type: 'success',
          });
          queryClient.refetchQueries('call');
        } catch (error) {
          UI.operations.showToast(error.response.data.message, {
            type: 'error',
          });
        }

        setSubmitting(false);
      }}
    >
      {({ setFieldValue, values, isSubmitting }) => (
        <Form className="space-y-4">
          {call?.startedAt === null ? (
            <div className="space-y-2">
              <Typography variant="h3" color="darkestGray">
                Início
              </Typography>
              <DatePicker
                showTimeSelect
                selected={values.startedAt}
                onChange={(date) => setFieldValue('startedAt', date)}
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
                className="text-base font-medium text-darkerGray p-3 rounded-xl placeholder:font-light placeholder:text-darkGray placeholder:text-sm"
                locale={ptBR}
                placeholderText="DD/MM/AAAA HH:MM"
              />
            </div>
          ) : (
            <div className="space-y-2">
              <Typography variant="h3" color="darkestGray">
                Chamado iniciado em
              </Typography>
              <Typography variant="p1" color="darkerGray">
                {format(new Date(call?.startedAt), 'dd/MM/yyyy HH:mm', {
                  locale: ptBR,
                })}
              </Typography>
            </div>
          )}

          {call?.endedCallTechnician === null && (
            <>
              <div className="space-y-2">
                <Typography variant="h3" color="darkestGray">
                  Fim
                </Typography>
                <DatePicker
                  showTimeSelect
                  selected={values.endTime}
                  onChange={(date) => setFieldValue('endTime', date)}
                  dateFormat="dd/MM/yyyy HH:mm"
                  timeFormat="HH:mm"
                  className="text-base font-medium text-darkerGray p-3 rounded-xl placeholder:font-light placeholder:text-darkGray placeholder:text-sm"
                  locale={ptBR}
                  placeholderText="DD/MM/AAAA HH:MM"
                />
              </div>

              <Button
                color="orange"
                type="submit"
                disabled={
                  (call?.startedAt === null && !values.startedAt) ||
                  (call?.startedAt !== null && !values.endTime) ||
                  isSubmitting
                }
              >
                Atualizar esse chamado
              </Button>
            </>
          )}
          {call?.endedCallTechnician !== null && (
            <div className="space-y-2">
              <Typography variant="h3" color="darkestGray">
                Chamado finalizado com a descrição :
              </Typography>
              <Typography variant="p1" color="darkerGray">
                {call?.endedCallTechnician?.description}
              </Typography>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export { FormSimulateTechnicianAction };
