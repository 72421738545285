import { useEffect, useMemo, useState } from 'react';
import { technicianStatuses } from 'lib/technician';
import { useUI } from 'hooks/useUI';
import { SidesheetTypes } from 'ducks/ui/types';
import { TableContainer } from 'components/Tables';
import {
  TechniciansError,
  TechniciansNotFound,
} from 'components/Tables/States';
import { StatusIndicator } from 'components/Tables/StatusIndicator';
import { Typography } from 'components/Typography';
import { Review } from 'components/Review';
import { Avatar } from 'components/Avatar';
import { TagList } from 'components/TagList';
import AlgoliaPagination from 'components/AlgoliaPagination';
import { connectHits } from 'react-instantsearch-dom';
import { Checkmark } from 'components/Checkmark';
import { openSidesheet } from 'assets/images/icons';
import { Hit } from 'react-instantsearch-core';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

type ServicesTechnicians = {
  id?: string;
  name: string;
  comment: string;
  description: string;
  certification: string;
};

type Profile = {
  id?: string;
  name: string;
  avatar: string;
  status: string;
  cnpj?: string;
  phone: string;
};

type Regions = {
  id?: string;
  name: string;
  uf: string;
};

interface TechniciansData {
  objectID: string;
  cnpj?: string;
  phone?: string;
  name?: string;
  profile: Profile;
  status?: string;
  avatar?: string;
  rating: number;
  regions: Regions[];
  services: ServicesTechnicians[];
  createdAt: Date;
}

interface TechniciansTableProps {
  hits: Hit<TechniciansData>[];
  handleRefresh(): void;
  resetRefresh(): void;
}

const TechniciansTable = ({
  hits,
  handleRefresh,
  resetRefresh,
}: TechniciansTableProps) => {
  const UI = useUI();
  const [loading, setLoading] = useState<boolean>(true);

  async function sleep() {
    return new Promise((res) => {
      setTimeout(() => res(true), 3000);
    });
  }

  async function handleOpenTechnician(data) {
    resetRefresh();
    if (
      data.profile.status === 'WAITING_FOR_APPROVAL' ||
      data.profile.status === 'WAITING_FOR_ACTIVATION' ||
      data.profile.status === 'REFUSED'
    ) {
      UI.operations.sidesheet.openSidesheet({
        type: SidesheetTypes.SidesheetTechnicianScreening,
        sidesheetProps: {
          id: data.profile.id,
          async onAfterUpdate() {
            await sleep();
            handleRefresh();
          },
        },
      });
    } else {
      UI.operations.sidesheet.openSidesheet({
        type: SidesheetTypes.SidesheetActiveTechnician,
        sidesheetProps: {
          id: data.profile.id,
          async onAfterUpdate() {
            await sleep();
            handleRefresh();
          },
        },
      });
    }
  }

  const dataTechnicians = useMemo<TechniciansData[]>(
    () =>
      (hits?.map((tech) => {
        return {
          profile: {
            id: tech?.objectID,
            avatar: tech?.avatar,
            name: tech?.name,
            cnpj: tech?.cnpj,
            phone: tech?.phone,
            status: tech?.status,
          },
          rating: tech?.rating,
          regions: tech?.regions?.map((region) => {
            return {
              id: region?.id,
              name: region?.name,
              uf: region?.uf,
            };
          }),
          services: tech?.services.map((service) => {
            return {
              id: service?.id,
              name: service?.name,
              comment: service?.comment,
              description: service?.description,
              certification: service?.certification,
            };
          }),
          createdAt: tech?.createdAt,
        };
      }) as TechniciansData[]) || [],
    [hits]
  );

  useEffect(() => {
    if (dataTechnicians.length >= 0) {
      setLoading(false);
    }
  }, [dataTechnicians]);

  const columns = [
    {
      Header: 'CNPJ',
      accessor: 'profile.cnpj',
      Cell: (cellProps) => {
        const isChecked = Boolean(
          cellProps.value && cellProps.value !== 'unknown'
        );
        return <Checkmark isChecked={isChecked} />;
      },
    },
    {
      Header: 'Status',
      accessor: 'profile.status',
      Cell: (cellProps) => {
        return (
          <StatusIndicator
            color={technicianStatuses[cellProps.value].color}
            status={technicianStatuses[cellProps.value].displayText}
          />
        );
      },
    },
    {
      Header: 'Profissional',
      accessor: 'profile',
      Cell: (cellProps) => {
        return (
          <div className="flex flex-row items-center space-x-3">
            <Avatar
              name={cellProps.value.name}
              photoURL={cellProps.value.avatar}
              status={cellProps.value.status}
            />
            <Typography variant="p1" color="darkerGray">
              {cellProps.value.name}
            </Typography>
          </div>
        );
      },
    },
    {
      Header: 'Data de cadastro',
      accessor: 'createdAt',
      Cell: (cellProps) => {
        return (
          <Typography variant="p1" color="darkerGray">
            {format(new Date(cellProps.value || null), 'dd/MM/yyyy HH:mm', {
              locale: ptBR,
            })}
          </Typography>
        );
      },
    },
    {
      Header: 'Avaliações',
      accessor: 'rating',
      Cell: (cellProps) => {
        return <Review rating={cellProps.value} />;
      },
    },

    {
      Header: 'Locais de atendimento',
      accessor: 'regions',
      Cell: (cellProps) => {
        return (
          <TagList
            expand={1}
            data={cellProps.value.map(
              (region) => region.name + ' - ' + region.uf
            )}
          />
        );
      },
    },
    {
      Header: 'Capacitações',
      accessor: 'services',
      Cell: (cellProps) => {
        return (
          <div className="flex flex-row justify-between">
            <TagList
              expand={3}
              data={cellProps.value.map((service) => service.name)}
            />
          </div>
        );
      },
    },
    {
      Header: '',
      accessor: 'sidesheet',
      Cell: () => {
        return (
          <img
            width={16}
            height={16}
            src={openSidesheet}
            className="cursor-pointer max-w-sm"
          />
        );
      },
    },
  ];

  return (
    <>
      <TableContainer
        columns={columns}
        data={dataTechnicians}
        error={false}
        loading={loading}
        onRowClick={handleOpenTechnician}
        ErrorComponent={TechniciansError}
        NotFoundComponent={TechniciansNotFound}
      />
      <div className="pt-2">
        <AlgoliaPagination />
      </div>
    </>
  );
};

export default connectHits<TechniciansTableProps, TechniciansData>(
  TechniciansTable
);
