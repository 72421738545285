/* eslint-disable */
import { defaultAvatar } from 'assets/images/icons';
import AlgoliaPagination from 'components/AlgoliaPagination';
import { Avatar } from 'components/Avatar';
import { CompanyIconLabel } from 'components/CompanyIconLabel';
import { TableContainer } from 'components/Tables';
import { StatusIndicator } from 'components/Tables/StatusIndicator';
import { Typography } from 'components/Typography';
import { SidesheetTypes } from 'ducks/ui/types';
import { useUI } from 'hooks/useUI';
import { callStatus } from 'lib/call';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { CallTableScheduleView } from '../CallTableScheduleView';
import { Hit } from 'react-instantsearch-core';
import { CallsNotFound } from 'components/Tables/States/CallsNotFound';
import { CallsError } from 'components/Tables/States/CallsError';
import { TagList } from 'components/TagList';
import { useRouter } from 'next/router';
import { ShoppingCart } from './ShoppingCart';
import { SlaCountdown } from './SlaCountdown';
import useUser from 'hooks/useUser';

type CallStatus = {
  color: string;
  displayText: string;
};

type TechnicianData = {
  name: string;
  avatar?: string;
};

type CallTimeData = {
  startTime: string;
  finalTime: string;
};

type CallsData = {
  id: number;
  status: string;
  time: CallTimeData;
  companyId: string;
  technicianId: string;
  technician: TechnicianData;
};

type CallHitData = {
  callId: number;
  technicianId: string;
  technicianName: string;
  technicianAvatar: string;
  companyId: string;
  status: string;
  startTime: string;
  finalTime: string;
  address?: {
    city: string;
    state: string;
  };
  needsParts: boolean;
  sla: {
    startCalls: number;
    startTime: Date;
    timeRemaining: number;
  };
  type: string;
  createdAt: Date;
};

interface CallsTableProps {
  hits: Hit<CallHitData>[];
}

const CallsTable = ({ hits }: CallsTableProps) => {
  const { operations } = useUI();
  const [loading, setLoading] = useState<boolean>(true);
  const user = useUser();
  const router = useRouter();

  const dataCalls = useMemo<CallsData[]>(() => {
    return (
      hits?.map((call) => ({
        id: call?.callId,
        companyId: call?.companyId,
        status: call?.status,
        technician: {
          name: call?.technicianName,
          avatar: call?.technicianAvatar,
        },
        technicianId: call?.technicianId,
        time: {
          startTime: call?.startTime,
          finalTime: call?.finalTime,
        },
        address: {
          city: call?.address.city,
          state: call?.address.state,
        },
        needsParts: call?.needsParts,
        sla: {
          sla: call?.sla.startCalls,
          startTime: call?.startTime,
          timeRemaining: call?.sla.timeRemaining,
        },
        other: {
          type: call?.type,
          needsParts: call?.needsParts,
          timeRemaining: call?.sla?.timeRemaining,
        },
      })) || []
    );
  }, [hits]);

  useEffect(() => {
    if (dataCalls.length >= 0) {
      setLoading(false);
    }
  }, [dataCalls]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Id',
        accessor: 'id',
        Cell: (cellProps) => (
          <Typography variant="p1" color="darkerGray" className="pr-4">
            {cellProps.value}
          </Typography>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (cellProps) => (
          <StatusIndicator
            color={callStatus[cellProps.value]?.color}
            status={callStatus[cellProps.value]?.displayText}
          />
        ),
      },
      {
        Header: 'Horário',
        accessor: 'time',
        Cell: (cellProps) => <CallTableScheduleView value={cellProps.value} />,
      },
      {
        Header: 'Local de atendimento',
        accessor: 'address',
        Cell: (cellProps) => (
          <TagList
            expand={1}
            data={
              cellProps.value.city
                ? [cellProps.value.city + ' - ' + cellProps.value.state]
                : []
            }
          />
        ),
      },
      {
        Header: 'Cliente',
        accessor: 'companyId',
        Cell: (cellProps) => <CompanyIconLabel companyId={cellProps.value} />,
      },
      {
        Header: 'Profissional',
        accessor: 'technician',
        Cell: (cellProps) =>
          cellProps.value.avatar || cellProps.value.name !== undefined ? (
            <div className="flex flex-row items-center space-x-3">
              <Avatar
                name={cellProps.value?.name}
                photoURL={cellProps.value?.avatar}
              />
              <Typography variant="p1" color="darkerGray">
                {cellProps.value?.name}
              </Typography>
            </div>
          ) : (
            <div className="flex flex-row items-center space-x-3">
              <Avatar name={'Não atribuído'} photoURL={defaultAvatar} />
              <Typography variant="p1" color="darkerGray">
                Não atribuído
              </Typography>
            </div>
          ),
      },
    ];

    if (user?.type === 'EuNerd') {
      return baseColumns.concat([
        {
          Header: 'Informações',
          accessor: 'other',
          Cell: (cellProps) => {
            const { needsParts, type, timeRemaining } = cellProps.value;
            const active = Boolean(needsParts === true);

            return (
              <div className="flex flex-row items-center align-middle space-x-1">
                <ShoppingCart active={active} />
                <SlaCountdown type={type} timeRemaining={timeRemaining} />
              </div>
            );
          },
        },
      ]);
    }
    return baseColumns;
  }, [user?.type]);

  const handleOpenCall = useCallback((data: CallsData) => {
    const finishedStatus = [
      callStatus.SUPPORT_CANCELED.value,
      callStatus.CALL_SUCCESSFULLY_ENDED.value,
      callStatus.UNDER_ANALYSIS.value,
      callStatus.UNPRODUCTIVE_CALL.value,
      callStatus.PENDING_COMPLETION.value,
    ];

    const type = finishedStatus.includes(String(data?.status))
      ? SidesheetTypes.SidesheetFinishedCalls
      : SidesheetTypes.SidesheetOpenCalls;

    operations.sidesheet.openSidesheet({
      type,
      sidesheetProps: {
        id: data.id,
        statusCall: data.status,
        onAfterClose: () => {
          router.replace(router.pathname);
        },
      },
    });
  }, []);

  useEffect(() => {
    if (router.query.callId) {
      const callIdFromUrl = Number(router.query.callId);
      const callData = dataCalls.find((call) => call.id === callIdFromUrl);

      if (callData) {
        handleOpenCall(callData);
      } else {
        router.replace(router.pathname);
      }
    }
  }, [router.query, dataCalls]);

  return (
    <>
      <TableContainer
        columns={columns}
        data={dataCalls}
        error={false}
        loading={loading}
        onRowClick={handleOpenCall}
        ErrorComponent={CallsError}
        NotFoundComponent={CallsNotFound}
      />
      <div className="pt-2">
        <AlgoliaPagination />
      </div>
    </>
  );
};

export default connectHits<CallsTableProps, CallHitData>(CallsTable);
