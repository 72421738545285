import api, { apiNotToken } from './api';

export const Technicians = {
  update: (payload, id) => api.patch(`/technicians/${id}`, payload),
  create: (payload) => api.post(`/technicians/register`, payload),
  approve: (id) => api.patch(`/technicians/approve/${id}`),
  refuse: (payload, id) => api.patch(`/technicians/refuse/${id}`, payload),
  deactivate: (id) => api.patch(`/technicians/disable-technician/${id}`),
  activate: (id) => api.patch(`/technicians/reactivate-technician/${id}`),
  delete: (id) => api.delete(`/technicians/${id}`),
  getAll: (params) => api.get('/technicians', { params }),
  get: async (id) => {
    const { data } = await api.get('/technicians/' + id);
    return data?.technician;
  },
  getCalls: (id, params) => api.get(`/technicians/${id}/calls`, { params }),
  banksList: () => api.get('/juno/banks'),
  validateEmail: (email: string) =>
    api.get(`/technicians/validate-email/${email}`),
  register: (payload) => apiNotToken.post(`/technicians/register`, payload),
  getReportTechnicians: () => api.get('/technicians/report'),
};
