import { SearchClient } from 'algoliasearch/lite';
import { AlgoliaTotalCount } from 'components/AlgoliaTotalCount';
import { TotalCountTechnicians } from '../TotalCountTechnicians';
import { IconButton } from 'components/IconButton';
import { plusSign } from 'assets/images/icons';
import { Technicians } from 'services/technician';
import { downloadCSV } from 'utils/downloadCSV ';
import { useUI } from 'hooks/useUI';

interface AlgoliaTotalCountTechniciansProps {
  searchClient: SearchClient;
  filters: string;
}

const AlgoliaTotalCountTechnicians = ({
  filters,
  searchClient,
}: AlgoliaTotalCountTechniciansProps) => {
  const UI = useUI();

  async function downloadReports() {
    try {
      const { data } = await Technicians.getReportTechnicians();
      downloadCSV({ data });
      UI.operations.showToast('Relatório gerado com sucesso');
    } catch (error) {
      UI.operations.showToast('Erro ao gerar relatório', {
        type: 'error',
      });
    }
  }
  return (
    <AlgoliaTotalCount
      filters={filters}
      searchClient={searchClient}
      indexName="technicians"
    >
      <div className="flex flex-row justify-between w-full align-baseline items-baseline">
        <TotalCountTechnicians />
        <IconButton
          icon={plusSign}
          size="small"
          filled
          onClick={() => downloadReports()}
        >
          Gerar relatório
        </IconButton>
      </div>
    </AlgoliaTotalCount>
  );
};

export { AlgoliaTotalCountTechnicians };
